.events-grid {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.event-frame {
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  width: 100%;
  max-width: 300px;
  cursor: pointer;
  position: relative;
  transition: transform 0.2s ease;
}
.event-frame-name {
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 300px;
  cursor: pointer;
  position: relative;
  transition: transform 0.2s ease;
}

.event-frame:hover {
  transform: scale(1.05);
}
.event-frame-name:hover {
  transform: scale(1.05);
}
.event-frame-time {
  display: flex;
  flex-direction: column;
}
.event-generic {
  margin-left: 20px;
}
.event-frame-info {
  white-space: pre-wrap; /* Preserves white spaces and newlines */
  font-size: 14px;       /* Adjust font size as needed */
  color: #666;           /* Subtle text color */
  margin-top: 10px;      /* Space above the info text */
  margin-left: 20px;      /* Space to the left of the info text */
  line-height: 1.5;      /* Improve readability */
  background-color: #f8f8f8; /* Light background to distinguish info */
  padding: 10px;         /* Padding for better spacing */
  text-align: left;    /* Center the text */
  border-radius: 5px;    /* Soft rounded edges */
}

.event-name {
  font-weight: bold;
  font-size: 1.2em;
  color: #2c3e50;
  margin-bottom: 10px;
}

.event-date-time {
  color: #7f8c8d;
  font-size: 1.2em;
  margin-top: 10px;
}

.delete-icon, .edit-icon {
  position: absolute;
  top: -15px;
  font-size: 24px; /* Increased size */
  cursor: pointer;
  width: 30px; /* Increased size */
  height: 30px; /* Increased size */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: white;
  border: 2px solid;
  transition: background-color 0.2s ease, color 0.2s ease;
  z-index: 10;
}

.edit-icon {
  top: -15px;
  right: 50px; /* Increased gap */
  color: #3498db;
  border-color: #3498db;
}

.delete-icon {
  top: -15px;
  right: 10px;
  color: #e74c3c;
  border-color: #e74c3c;
}

.delete-icon:hover, .edit-icon:hover {
  background-color: #e74c3c;
  color: white;
}

.edit-icon:hover {
  background-color: #3498db;
  color: white;
}

.confirm-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20; /* Ensure the modal is on top */
}

.confirm-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.confirm-modal-content p {
  margin-bottom: 20px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
}

.modal-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-buttons button.cancel-button {
  background-color: #dc3545;
  color: white;
}

.create-event {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #ccc;
  cursor: pointer;
}

.create-event-placeholder {
  font-size: 2em;
  color: #ccc;
}

.create-event-form-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  grid-column: 1 / -1; /* Span all columns */
}

.create-event-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%; /* Make the form wider */
  max-width: 600px; /* Increase max-width */
  padding: 20px;
  border: 1px solid #ddd;
  background-color: white;
  border-radius: 8px;
}

.create-event-form input,
.create-event-form textarea {
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px; /* Ensure font-size is at least 16px */
}

.create-event-form input.input-error {
  border-color: #dc3545;
}

.create-event-form .error-message {
  color: #dc3545;
  font-size: 0.875em;
  margin: -10px 0 10px 0;
}

.create-event-form label {
  align-self: flex-start;
  margin: 10px 0 5px 0;
  font-weight: bold;
}

.create-event-form .form-buttons {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
}

.create-event-form button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.create-event-form button:hover {
  opacity: 0.8;
}

.create-event-form button:first-of-type {
  background-color: #28a745;
  color: white;
}

.create-event-form button.cancel-button {
  background-color: #dc3545;
  color: white;
}

.past-event:hover {
  transform: none;
  box-shadow: none;
}

.past-event .event-name, .past-event .event-date-time {
  color: #aaa;
}

.past-event .delete-icon, .past-event .edit-icon {
  display: none;
}
