.lendings-view .clickable-text {
  cursor: pointer;
  color: blue; /* Color to suggest it's clickable */
}
.lendings-description {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  line-height: 1;
}
.lendings-member-name {
  font-weight: bold;
  margin-right: 10px;
}
.lendings-description {
}
