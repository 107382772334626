/* Container for the toggle switch */
.toggle-switch {
  width: 100px; /* Adjust the width as needed */
  height: 40px; /* Reduced height */
  position: relative;
  border-radius: 4px; /* Rectangular with slight rounding */
  background-color: #2c3e50; /* Same dark blue background color as buttons */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, sans-serif; /* Ensure consistent font */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* The slider (background of the switch) */
.toggle-switch .slider {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #27ae60; /* Matching button background color */
  border-radius: 4px; /* Matches container */
  transition: background-color 0.4s, box-shadow 0.3s ease;
}

/* Thinner rectangular handle */
.toggle-switch .slider::before {
  content: "";
  position: absolute;
  height: 100%; /* Full height of the switch */
  width: 20%; /* Slightly thinner handle */
  background-color: #ecf0f1; /* Light gray handle */
  border-radius: 4px;
  top: 0;
  left: 80%; /* Start with the handle on the right */
  transition: left 0.4s, background-color 0.4s;
}

/* Label centered within the switch */
.toggle-switch .label {
  position: relative;
  z-index: 1;
  font-size: 13px; /* Ensure consistent font size */
  color: white;
  transition: color 0.4s;
  font-family: Arial, sans-serif; /* Apply the same font as other buttons */
  font-weight: 600; /* Match the button font weight */
}

/* Handle position and label when checked */
input[type="checkbox"]:checked + .toggle-switch .slider::before {
  left: 0%; /* Move the handle to the left when checked */
}

/* Background color and label when checked */
input[type="checkbox"]:checked + .toggle-switch .slider {
  background-color: #e74c3c; /* Red background color when checked */
}


/* Hover effect */
.toggle-switch:hover {
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.25); /* More pronounced shadow */
}

/* Active state */
.toggle-switch:active {
  background-color: #1c2833; /* Even darker shade when the switch is pressed */
}

