.cluster-dashboard {
  padding: 0px;
}

[class$="-dashboard"] nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

[class$="-dashboard"] nav .nav-links-operations a {
  margin-right: 15px;
  text-decoration: none;
  font-weight: bold;
}
.nav-links-operations {
	display: flex;
}
.nav-links-personal {
	display: flex;
	gap: 20px; /* Add spacing between links */
}
[class$="-dashboard"] nav .nav-links-personal a {
  margin-right: 15px;
  text-decoration: none;
  color: #007BFF; /* Different color for right-aligned links */
  font-weight: bold;
}

[class$="-dashboard"] nav .nav-links-dashboard a:hover {
  text-decoration: underline;
}

[class$="-content"]  {
  padding: 10px;
  background-color: #f9f9f9;
}

@media (max-width: 768px) {
  [class$="-content"] {
    margin-bottom: 40px;
  }
}

.nav-links-dashboard {
  display: flex;
  gap: 20px; /* Add spacing between links */
}

.nav-links-dashboard a {
  padding: 10px 15px;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s, color 0.3s;
}



.nav-links-dashboard a:hover {
}


.nav-links-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	max-width: 100%;
}

.nav-personal-dashboard {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #f0f8ff; /* Light blue background for differentiation */
  border-radius: 8px;
}

.nav-right-dashboard {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #f0f8ff; /* Light blue background for differentiation */
  padding: 10px;
  border-radius: 8px;
}

.nav-right-header {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #007BFF; /* Different color for header */
}

.nav-right-links-dashboard {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.nav-link {
  text-decoration: none;
  color: #333; /* Adjust color as needed */
  font-size: 1.25rem;
}

.nav-link-right {
  text-decoration: none;
  color: #007BFF; /* Different color for right-aligned links */
  font-weight: bold;
}

.nav-link-personal {
  text-decoration: none;
  color: #007BFF; /* Different color for right-aligned links */
  font-weight: bold;
  font-size: 1.25rem;
  display: inline-block;
  text-align: center;
}

.nav-link-cluster {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-size: 1.25rem;
}

.nav-link-events {
  padding: 10px;
  text-decoration: none;
  color: #007BFF; /* Different color for right-aligned links */
  font-weight: bold;
}

.nav-link-right:hover {
  text-decoration: underline;
}


.nav-links-selection {
  display: flex;
  text-decoration: none;
  color: #28a745; /* Green color to differentiate */
  font-weight: bold;
  border: 1px solid #28a745;
  padding: 5px 0px 0px 0px;
  border-radius: 4px;
  background-color: #e9f7ef; /* Light green background */
  text-align: left; 
  font-size: 1.25rem;
}

.nav-links-selection:hover {
	transform: scale(1.05);
}

.event-name-label {
  margin-right: 10px;
  margin-left: 15px;
}

.cluster-control-panel-column {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.cluster-control-panel {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.requests-summary {
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.requests-summary.expanded {
  background-color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.requests-summary h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cart-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  gap: 10px;
}

.cart-circle {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-left: 10px;
  background-color: #3498db;
  color: white;
  text-align: center;
  border-radius: 50%;
  font-weight: bold;
}

.expanded-cart-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.requests-summary.expanded .expanded-cart-content {
  max-height: 500px; /* Set this to the height you expect the content to be */
}

.requests-summary ul {
  list-style-type: none;
  padding: 0;
}

.requests-summary li {
  margin: 5px 0;
}

.nav-cluster-info {
  display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
	gap: 10px;
}

.nav-cluster-header {
  font-weight: bold;
	font-size: 2rem;
}

.span-cluster-name {
  padding: 10px 15px;
}


/* New CSS to handle the styling for the modified links */
.nav-link-cluster {
  display: inline-block;
  text-align: center;
  font-size: 1.25rem; /* Main font size for the second word */
}

.nav-link-small {
  font-size: 0.8rem; /* Smaller font size for the first word */
  display: block;
}

.nav-link-cluster.active {
  font-weight: bold;
}

/* Styling for the navigation link slider */
.nav-links-dashboard {
  display: flex;
  justify-content: center; /* Center the content horizontally */
  width: 100%; /* Full width of the parent container */
  overflow: hidden; /* Hide any overflow to keep it within bounds */
  box-sizing: border-box; /* Include padding and borders in the element’s width */
}

.nav-links-slider {
  position: relative;
  width: 100%; /* Take full width of the parent container */
  max-width: 100%; /* Ensure it doesn't exceed the parent's width */
  overflow: hidden; /* Hide overflow to prevent content spilling out */
  white-space: nowrap;
  padding: 10px;
  background-color: #f0f8ff; /* Light blue background for the slider */
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.nav-links-items {
  display: flex; /* Flex layout for horizontal scrolling */
  gap: 10px; /* Space between each link */
  overflow-x: auto; /* Enable horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  padding: 10px;
  width: 100%; /* Full width of the nav-links-slider */
  max-width: 100%; /* Constrain width within parent */
  box-sizing: border-box; /* Include padding and border in the width */
}

.nav-links-items::-webkit-scrollbar {
  display: none; /* Hide scrollbar for a clean look */
}

.nav-link-item {
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  color: #007BFF; /* Link text color */
  background-color: #ecf0f1; /* Light grey background */
  border: 2px solid #007BFF; /* Blue border for links */
  border-radius: 5px; /* Rounded corners for links */
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
}

.nav-link-item:hover {
  background-color: #007BFF; /* Change background on hover */
  color: #fff; /* Change text color on hover */
  border-color: #0056b3; /* Darker border on hover */
}

.nav-link-item.active {
  background-color: #007BFF; /* Active link background */
  color: #fff; /* Active link text color */
  border-color: #0056b3; /* Active link border color */
}

.nav-links-slider:before,
.nav-links-slider:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  pointer-events: none;
  z-index: 1;
}

.nav-links-slider:before {
  left: 0;
  background: linear-gradient(to right, rgba(240, 248, 255, 1), rgba(240, 248, 255, 0));
}

.nav-links-slider:after {
  right: 0;
  background: linear-gradient(to left, rgba(240, 248, 255, 1), rgba(240, 248, 255, 0));
}

@media (max-width: 468px) {
  .nav-link-item {
    padding: 8px 15px; /* Adjust padding for smaller screens */
    margin-right: 5px; /* Add space between links */
    font-size: 0.9rem; /* Smaller font size for better fit */
  }
}

/* Mobile Header Styles */
/* Mobile Header Styles with Subtler Design */
.mobile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f0f8ff; /* Subtle light blue background */
  color: #333; /* Dark text color for contrast */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Lighter shadow for subtle effect */
  border-bottom: 1px solid #ddd; /* Thin border for subtle separation */
  margin: 0;
}

.mobile-content	{
  padding: 0px;
  padding-right: 10px;
  padding-left: 10px;
}

/* Styled Dropdown Menu Matching Nav Links */
.dropdown-menu {
  position: relative;
}

.dropdown-button {
  background-color: #ecf0f1; /* Light grey background to match nav-links-slider */
  color: #007BFF; /* Consistent blue text color */
  padding: 10px;
  border: 2px solid #007BFF; /* Blue border to match links */
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  white-space: normal;
  transition: background-color 0.3s, color 0.3s;
}

.dropdown-button:hover {
  background-color: #d4e4ff; /* Slight hover effect */
}

.dropdown-arrow {
  margin-left: 5px;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f0f8ff; /* Light blue background to match slider */
  border: 2px solid #007BFF; /* Blue border for consistency */
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  min-width: 150px; /* Ensure the dropdown has a minimum width */
}

.dropdown-content button {
  width: 100%;
  padding: 10px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  color: #007BFF; /* Consistent blue color */
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.dropdown-content button:hover {
  background-color: #d4e4ff; /* Light blue hover effect to match */
}

/* Active state for the selected dropdown option */
.dropdown-content button.active {
  background-color: #007BFF; /* Blue background for selected option */
  color: #fff; /* White text color for contrast */
}

/* Event Name Selector Styled Similar to Dropdown */
.event-name-selector {
  margin-left: 10px;
}

.event-name-button {
  background-color: #ecf0f1; /* Light grey background */
  color: #007BFF; /* Blue text for consistency */
  padding: 10px;
  border: 2px solid #007BFF; /* Blue border */
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.event-name-button:hover {
  background-color: #d4e4ff; /* Light blue hover effect */
}

/* Event Info Modal Styles */
.event-info-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.event-info-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 400px;
}

.event-name-button {
  background-color: #ecf0f1; /* Light grey background */
  color: #007BFF; /* Blue text */
  padding: 10px;
  border: 2px solid #007BFF; /* Blue border */
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.event-info-button {
  background-color: #f39c12; /* Orange background for info button */
  color: #fff; /* White text */
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.event-info-button:hover {
  background-color: #e67e22; /* Darker orange on hover */
}

.close-modal {
  background: #007BFF;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}
/* Styles for Requests Cart Button */
.requests-cart-button {
  position: relative; /* Allows absolute positioning of the badge */
  margin-top: 7px;
  background-color: #007BFF;
  color: white;
  padding: 10px 10px; /* Adequate padding for touch targets */
  margin-left: 15px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for the button */
  transition: background-color 0.3s ease;
  flex-shrink: 0; /* Prevent the button from shrinking */
  font-size: 1rem; /* Consistent font size */
}

.requests-cart-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.requests-cart-button .cart-badge {
  position: absolute; /* Positioning relative to the button */
  top: -5px; /* Position at the top */
  right: -5px; /* Position to the right */
  background-color: red; /* Badge color */
  color: white;
  border-radius: 50%;
  padding: 5px 8px;
  font-size: 0.8rem;
  font-weight: bold;
  display: flex;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2); /* Subtle shadow for the badge */
}
/* Cart View Styles */
.cart-view {
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8; /* Light grey background */
  border: 1px solid #ddd; /* Border to separate from the rest of the content */
  border-radius: 8px; /* Rounded corners */
  margin-top: 20px; /* Space above cart view */
  width: 100%; /* Full width within the parent */
}

.cart-view-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.close-icon {
  cursor: pointer;
  font-size: 1.2rem;
  color: #333;
  padding: 5px 10px;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.close-icon:hover {
  background-color: #f0f0f0;
  color: #ff0000;
}

.cart-content {
  list-style-type: none;
  padding: 0;
}

.cart-content ul {
  padding: 0;
}

.cart-content li {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: white;
}
.confirm-cart-button {
  background-color: #28a745; /* Green background for confirmation */
}
