/* header.css */

.App-header {
  background-color: #282c34;
  padding: 10px 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap; /* Prevent wrapping */
}

.header-title {
  font-size: 2.5rem;
  text-align: center;
  margin: 5px;
  font-family: "Roboto", sans-serif;
}
.header-left {
  display: flex;
  flex-shrink: 0; /* Prevent the header-left from shrinking */
}



.nav-links {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: nowrap; /* Prevent wrapping */
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

.nav-links a:hover {
  text-decoration: underline;
}

.header-right {
  display: flex;
  align-items: center;
  flex-shrink: 0; /* Prevent shrinking */
  margin-right: 10px; /* Add a small margin to separate from the dropdown */
}

.header-right p {
  margin: 0 10px 0 0;
}

/* Dropdown menu for mobile */
.dropdown {
  display: none;
  position: relative;
}

.horizontal-dropdown-list {
  position: absolute;
  top: 100%; /* Positioning the dropdown list below the button */
  left: 0; /* Aligning with the left edge of the dropdown button */
  background-color: #2c2f36; /* Matching the color with the rest of the header */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Adding shadow for a better visual */
  border-radius: 4px;
  z-index: 1000; /* Ensuring it appears above other elements */
  padding: 8px 0; /* Padding for spacing inside the dropdown */
  display: none; /* Hidden by default */
}

.dropdown-list {
  position: absolute;
  top: 100%; /* Positioning the dropdown list below the button */
  left: 0; /* Aligning with the left edge of the dropdown button */
  background-color: #2c2f36; /* Matching the color with the rest of the header */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Adding shadow for a better visual */
  border-radius: 4px;
  z-index: 1000; /* Ensuring it appears above other elements */
  padding: 8px 0; /* Padding for spacing inside the dropdown */
  display: none; /* Hidden by default */
}

.dropdown-options {
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  margin-top: 5px;
  padding: 0;
}
.dropdown-option {
  padding: 10px;
  cursor: pointer;
  list-style: none;
  border-bottom: 1px solid #ccc;
}

.dropdown-option:last-child {
  border-bottom: none;
}

.dropdown-option:hover {
  background-color: #f0f2f5;
}

.dropdown-list a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-list a:hover {
  background-color: #575a5d;
}

.dropdown:hover .dropdown-list {
  display: block;
}

.menu-icon {
  display: none;
  cursor: pointer;
}

.dropdown-button {
  background-color: #61dafb;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
}

/* Mobile Styles */
@media (max-width: 868px) {
  .App-header {
    align-items: flex-start;
  }

  .menu-icon {
    display: none; /* Hide the menu icon on mobile */
  }

  .nav-links {
    display: none;
  }

  .dropdown {
    display: flex;
    left: 0;
  }
}


@media (max-width: 680px) {
   .header-title {
      display: none;
    }	
}
