
/* Existing styles... */
/*apply styles for div id root*/
body {
  font-family: Arial, sans-serif;
  background-color: #f0f2f5;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}


.clickable {
  cursor: pointer;
  color: blue;
}

.nav {
  display: flex;
  align-items: center;
  gap: 20px;
}

nav ul {
  display: flex;
  gap: 20px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 1.25rem;
}

nav ul li {
  margin: 0;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
}

nav ul li a:hover {
  text-decoration: underline;
}
.clickable:hover {
  color: darkblue;
}


button {
  background-color: #2c3e50; /* Deep blue color for the button background */
  color: #ecf0f1; /* Light grey text for readability */
  font-size: 1.1rem; /* Slightly larger font size for better accessibility */
  font-weight: bold; /* Bold text to make the button stand out */
  padding: 12px 20px; /* Adequate padding for comfort while clicking */
  border: none; /* No border to keep the design clean */
  border-radius: 8px; /* Rounded corners for a modern look */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for a 3D effect */
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  cursor: pointer; /* Changes cursor to a pointer on hover */
  margin: 3px;
}

button:hover {
  background-color: #34495e; /* Slightly lighter shade of deep blue on hover */
  transform: translateY(-2px); /* Slight upward movement to indicate interactivity */
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.25); /* Slightly more pronounced shadow on hover */
}

button:active {
  background-color: #1c2833; /* Even darker shade when the button is pressed */
  transform: translateY(0px); /* Reset the movement */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Reset the shadow */
}

button:focus {
  outline: 2px solid #34495e; /* Outline for accessibility when focused */
  outline-offset: 2px; /* Slight gap between the button and the outline */
}
.main-content {
  margin: 0px;
}

.import-export-manage {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; /* Allow wrapping for small screens */
  margin-bottom: 20px;
  align-items: center; /* Center align items vertically */
}

form {
  margin: 0; /* Remove default margin */
  width: 100%; /* Full width for the form */
}

input, select {
  padding: 10px;
  font-size: 1rem;
  box-sizing: border-box; /* Include padding and border in the element's width and height */
  
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0; /* Remove default margin */
}

li {
  list-style-type: none;
}

h2 {
  margin-top: 20px;
  font-size: 2rem;
  text-align: left;
}

div {
  margin: 5px 0;
}

.root {
  margin: 0px;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .import-export-manage {
    flex-direction: column;
    align-items: center;
  }

  .import-export-manage div {
    margin: 10px 0;
  }
}

/* Additional styles... */

.top-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.set-select,
.form-selects {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.set-select label,
.form-selects label {
  margin-right: 10px;
  font-size: 1rem;
}

.action-buttons {
  display: flex;
  align-items: center;
}

.action-buttons form {
  margin: 0 10px 0 0;
}

.add-remove-form {
  display: flex;
  align-items: flex-start;
}

.card-form {
  display: flex;
  align-items: center;
}

.add-remove-form select,
.add-remove-form input,
.card-form input {
  margin-right: 5px;
  margin-left: 5px;
}

.add-remove-form input[type="text"],
.card-form input[type="text"] {
  max-width: 100%;
}

.card-form input[type="text"][placeholder="Card ID or Name"] {
  width: 300px; /* Up to 50 characters */
}

.card-form input[type="text"][placeholder="Quantity"] {
  width: 50px; /* Up to 3 figures */
}

.button-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}

.left-buttons,
.right-buttons {
  display: flex;
  gap: 10px;
}

.history-view, .collection-view {
  text-align: left;
}

.history-view ul, .collection-view ul {
  list-style-type: none;
  padding-left: 0;
}

.history-view li, .collection-view li {
  display: flex;
  align-items: center;
  margin-bottom: 5px; /* Reduced margin between rows */
  background-color: white;
  padding: 8px; /* Reduced padding inside the item */
  border-radius: 5px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.history-view li span, .collection-view .clickable {
  cursor: pointer;
}

.collection-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 0; /* Reduced padding around the container */
  box-sizing: border-box;
  margin-bottom: 5px; /* Reduce the margin between containers */
}

@media (max-width: 768px) {
  .collection-item-container {
	  flex-direction: column;
	  width: 100%;
	  max-width: 100%;
	  align-items: flex-start;
	  margin-right: 10px;
	  justify-content: space-between;
  }
  .request-lend {
    margin-left: auto;
  }
}

.collection-item-wrapper {
  flex: 1;
  margin-right: 5px; /* Reduced margin between collection-item-wrapper and normal-foil-container */
}

.collection-item {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 10px; /* Rounded corners */
  padding: 8px;       /* Reduced padding inside the item */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for a lifted effect */
  flex: 1; /* Make the collection-item take up remaining space */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis if text overflows */
  font-size: 1.25em;
}


.request-lend {
  display: flex;
  align-items: center;
  padding: 5px 5px;
}

.request-lend button {
  margin-right: 5px;
}

.request-controls {
  display: flex;
  align-items: center;
}

.request-controls button {
  margin: 0 5px;
  padding: 5px 10px;
  font-size: 1.25em;
  border-radius: 8px; /* Rounded corners */
}

.request-quantity {
  display: inline-block;
  width: 30px;
  text-align: center;
}

.quantity-button {
  padding: 10px;
  font-size: 1rem;
  background-color: white; /* White background */
  border: 2px solid #3498db; /* Blue border */
  border-radius: 8px; /* Rounded corners */
  color: #3498db; /* Blue text color */
  cursor: pointer;
  width: 10vw; /* Set a proportional width to the viewport width */
  max-width: 100px; /* Set a max width for larger screens */
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
  transition: box-shadow 0.3s; /* Transition effect for box-shadow */
}

.quantity-button:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Slightly larger shadow on hover */
  background-color: white;
}

.clear-button {
  margin-left: 5px;
  padding: 5px 10px;
  font-size: 0.9em;
  background-color: #f44336;
  color: white;
  border: none;
  cursor: pointer;
}

.clear-button:hover {
  background-color: #e53935;
}

.remove-button {
  margin-left: 10px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
}

.remove-button:hover, .clear-button:hover {
  background-color: #c0392b;
}

.request-button {
  background-color: white; /* White background */
  color: #3498db; /* Blue text color */
  border: 2px solid #3498db; /* Blue border */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
  transition: box-shadow 0.3s; /* Transition effect for box-shadow */
}
.request-button:hover {
  background-color: white;
}
.collection-view .card-info {
  flex: 1;
  text-align: left; /* Align text to the left */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis if text overflows */
}

.collection-view .clickable-text {
  cursor: pointer;
  color: blue; /* Color to suggest it's clickable */
}

.normal-foil-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 10px; /* Add some space between collection-item and normal-foil-container */
}

.normal-field,
.foil-field,
.normal-field-small,
.foil-field-small{
  white-space: nowrap;
  background-color: #f9f9f9;
  padding: 2px 5px;
  border: 1px solid black;
  border-radius: 4px;
  font-size: 1.25em;
  cursor: pointer;
}

.normal-field-small,
.foil-field-small {
  display: none;
}

.color-square {
  width: 10px;
  height: 20px;
  margin-right: 5px;
}

.color-square.amber {
  background-color: #FFBF00;
}

.color-square.amethyst {
  background-color: #9966CC;
}

.color-square.emerald {
  background-color: #50C878;
}

.color-square.ruby {
  background-color: #E0115F;
}

.color-square.sapphire {
  background-color: #0F52BA;
}

.color-square.steel {
  background-color: #888888;
}

/* Base styles for all rarity badges */
.rarity-badge {
  display: inline-block;
  padding: 5px 5px 0 5px;
  height: 20px;
  line-height: 16px;
  margin-right: 5px;
  border: 1px solid black;
  color: black;
  font-weight: bold;
  border-radius: 4px;
  text-align: center; /* Center text horizontally */
  vertical-align: middle; /* Align text vertically */
}

/* Specific background colors for each rarity */
.rarity-badge.legendary {
  background-color: gold;
}

.rarity-badge.super-rare {
  background-color: silver;
}

.rarity-badge.rare {
  background-color: #e28743; /* Bronze color */
}

.rarity-badge.uncommon {
  background-color: white;
}

.rarity-badge.common {
  background-color: white;
}

.rarity-badge.enchanted {
  background-color: #DDA0DD; /* Light purple background */
}
.add-remove-form input[type="text"][placeholder="Card ID or Name"],
.card-form input[type="text"][placeholder="Card ID or Name"] {
  width: 200px;
  position: relative;
  width: 100%;
}

@media (max-width: 768px) {
  .top-controls {
    flex-direction: column;
    align-items: flex-start;
  }

  .set-select,
  .form-selects {
    margin-bottom: 10px;
  }

  .action-buttons {
    flex-direction: column;
    align-items: flex-start;
  }

  .action-buttons form,
  .action-buttons button {
    margin-bottom: 10px;
  }

  .add-remove-form {
    width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  }

  .card-form {
    flex-direction: column;
    align-items: flex-start;
  }

  .add-remove-form input[type="text"][placeholder="Card ID or Name"],
  .card-form input[type="text"][placeholder="Card ID or Name"] {
    position: relative;
    width: 100%;
  }

  .add-remove-form input[type="text"][placeholder="Quantity"],
  .card-form input[type="text"][placeholder="Quantity"] {
    width: 100%;
  }


}

.paste-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.paste-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 500px; /* Maximum width for larger screens */
  max-height: 90vh; /* Ensure the modal content doesn't exceed the viewport height */
  overflow-y: auto; /* Enable scrolling if content exceeds modal height */
}

.paste-modal-content h3 {
  margin-top: 0;
}

.paste-modal-content textarea {
  width: 100%;
  height: calc(100vh - 200px); /* Adjust height relative to viewport, leaving space for header, padding, etc. */
  max-height: 300px; /* Limit max height to fit within modal */
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box; /* Include padding and border in the element's width and height */
  resize: none; /* Disable manual resizing */
}

@media (max-width: 600px) {
  .paste-modal-content {
    width: 95%; /* Adjust modal width for smaller screens */
    padding: 15px; /* Reduce padding on smaller screens */
  }

  .paste-modal-content textarea {
    height: calc(100vh - 150px); /* Adjust textarea height for smaller screens */
    max-height: 200px; /* Further limit height to fit smaller screens */
  }
}
.error-message {
  color: red;
  margin-bottom: 10px;
}

.paste-modal-content button {
  margin-right: 10px;
}

.header-logout {
  display: flex;
  align-items: center;
  gap: 10px;
}

.custom-select {
  display: inline-block; /* Allows the container to fit the content */
  width: auto; /* Adjusts width automatically based on content */
  position: relative; /* Needed for positioning dropdown options */
  flex-shrink: 0; /* Prevents the select from shrinking */
}

.select-button {
  padding: 10px;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  color: #333; /* Dark gray text */
  background-color: #fff;
  cursor: pointer;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.select-button:hover {
  color: #333; /* Ensuring the text color remains the same */
  background-color: #fff; /* Ensuring the background color remains the same */
}

.select-button::after {
  content: '▼';
  font-size: 0.9rem;
  color: gray;
  margin-left: auto;
}

@media (max-width: 768px) {


  .main-input {
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
  }
}

/* Toggle Button Style */
.toggle-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #34495e; /* Darker background for contrast */
  color: #ecf0f1; /* Light text color */
  border-radius: 25px; /* Rounded shape to suggest toggle functionality */
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

/* Toggle Button Hover Effect */
.toggle-button:hover {
  background-color: #34495e; /* Darker background for contrast */
  box-shadow: 0px 0px 10px rgba(26, 188, 156, 0.5); /* Glow effect */
}

/* Active State for Toggle Button */
.toggle-button-active {
  background-color: #1abc9c; /* Active background color */
  color: #ffffff; /* White text */
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.2); /* Inner shadow */
}
.small-text {
  font-size: 0.75rem;
  font-weight: normal;
}

/* Header styles */
.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50; /* Deep blue background */
  color: #ecf0f1; /* Light text color */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}


.header-subtitle {
  font-size: 1.2rem;
  margin: 0;
  color: #bdc3c7; /* Slightly lighter than the title */
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-links a {
  color: #ecf0f1;
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #bdc3c7; /* Lighter shade on hover */
}

.header-right {
  display: flex;
  align-items: center;
  gap: 10px;
}
/* Main Navigation Links */
.main-link {
  font-size: 1.3rem;
  font-weight: bold;
  color: #ecf0f1; /* Light text color */
  padding: 10px 20px;
  margin: 0 10px;
  text-decoration: none;
  border-radius: 5px;
  transition: color 0.3s ease, text-shadow 0.3s ease;
  position: relative;
}

.main-link:hover {
  color: #ffffff; /* Slightly brighter on hover */
  text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.6); /* Subtle glow effect */
}

/* Underline Effect */
.main-link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 0;
  height: 2px;
  background-color: #ffffff;
  transition: width 0.3s ease;
}

.main-link:hover::after {
  width: 100%; /* Underline expands to full width on hover */
}
.header-logout span {
  font-weight: bold;
  margin-right: 10px;
}

button {
  background-color: #3498db;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

button:hover {
  background-color: #2980b9;
}

button:active {
  background-color: #1c6a9e;
  transform: translateY(2px);
}




.join-link-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.join-link-container input {
  padding: 5px;
  margin-right: 10px;
  flex: 1;
}

.copy-button {
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.copy-button:hover {
  background-color: #45a049;
}
.warning {
  background-color: #ffdddd;
  color: #d8000c;
  padding: 15px;
  margin-top: 10px;
  text-align: center;
  border: 1px solid #d8000c;
  border-radius: 5px;
  font-size: 16px;
}
.loading-message {
  margin-top: 10px;
  padding: 10px;
  background-color: #f0f8ff; /* Light blue background */
  color: #007bff; /* Blue text color */
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  border: 1px solid #007bff; /* Matching border color */
  animation: pulse 1.5s infinite; /* Add a subtle animation */
}

/* Optional: Add a subtle pulse animation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.disabled-button {
  background-color: #ccc; /* Gray background to indicate disabled */
  color: #666; /* Darker gray text for contrast */
  cursor: not-allowed; /* Change cursor to indicate disabled */
  border: 1px solid #aaa; /* Slightly darker border */
  pointer-events: none; /* Prevent interactions */
  box-shadow: none; /* Remove any shadow */
}

.disabled-button:hover {
  background-color: #ccc; /* Ensure no hover effect */
  box-shadow: none; /* Ensure no shadow on hover */
}

.disabled-button:active {
  transform: none; /* Prevent active state animation */
}
.export-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px 0;
}

.export-btn {
  background-color: #2E86C1;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
}

.export-btn:hover {
  background-color: #1B4F72;
}

.dreamborn-input-container {
  margin-top: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.file-upload-label {
  font-size: 14px;
  margin-bottom: 10px;
  display: block;
}

.file-input {
  width: 100%;
  margin-bottom: 10px;
}

.download-btn {
  background-color: #28A745;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.download-btn:disabled {
  background-color: #94D3A2;
  cursor: not-allowed;
}

.cancel-btn {
  background-color: #E74C3C;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
}

.cancel-btn:hover {
  background-color: #C0392B;
}

.submitted-added-message {
  position: absolute;
  top: -0px; /* Move it higher above the input */
  left: 25%; /* Position it centered relative to the input */
  transform: translateX(-50%); /* Center it horizontally */
  height: auto; /* Adjust the height accordingly */
  background-color: rgba(0, 255, 0, 0.7);
  color: black;
  font-size: 14px;
  border-radius: 3px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: slide-up 1.5s ease-out forwards; /* Faster animation */
  z-index: 10; /* Ensure it's on top */
}

.submitted-playset-message {
  position: absolute;
  top: -0px; /* Move it higher above the input */
  left: 75%; /* Position it centered relative to the input */
  transform: translateX(-50%); /* Center it horizontally */
  height: auto; /* Adjust the height accordingly */
  background-color: rgba(255, 255, 0, 0.7); /* Transparent yellow background */
  color: black;
  font-size: 14px;
  border-radius: 3px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: slide-up 1.5s ease-out forwards; /* Faster animation */
  z-index: 10; /* Ensure it's on top */
}

.submitted-removed-message {
  position: absolute;
  top: -0px; /* Move it higher above the input */
  left: 25%; /* Position it centered relative to the input */
  transform: translateX(-50%); /* Center it horizontally */
  height: auto; /* Adjust the height accordingly */
  /*Transparent light red background */
  background-color: rgba(255, 0, 0, 0.7);
  color: black;
  font-size: 14px;
  border-radius: 3px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: slide-up 1.5s ease-out forwards; /* Faster animation */
  z-index: 10; /* Ensure it's on top */
}


@keyframes slide-up {
  0% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -30px); /* Move it higher up */
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50px); /* Move it even higher */
  }
}

@media (max-width: 480px) {

    .App {
        padding: 0px;
        margin: 0;
    }

    .toggle-switch {
        width: 100%; /* Full width toggle for mobile */
        height: auto;
        padding: 5px;
    }

}

button, input[type="submit"], .quantity-button {
    padding: 10px 15px; /* Ensure padding for touch-friendly interactions */
    border-radius: 5px;
}

.input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

@media (max-width: 768px) {
   .normal-field, .foil-field {
     display: none;
   }
   .normal-field-small, .foil-field-small {
     display: inline;
   }
}

.expandable-icon {
  cursor: pointer;
  font-size: 1.5em; /* Size of the icon */
  margin-top: 0px;
  margin-left: 10px; /* Slight movement to indicate interactivity */
  text-align: center;
  color: #007bff; /* Color to match your theme */
}

.expandable-icon:hover {
  color: #0056b3; /* Darker blue on hover */
}
.personal-content {
  margin: 0px;
  padding: 0px;
}

.input-filter-form {
  width: 100%;
  max-width: 500px;
  position: relative;
}

.main-input {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  left: 0;
  display: inline-block;
}

.card-input-field {
  width: 100%;
  padding: 10px 50px 10px 10px; /* Padding for icons */
  border: 2px solid #007BFF; /* Blue border */
  border-radius: 5px; /* Slightly rounded corners for rectangle */
  font-size: 1rem;
  box-sizing: border-box;
  background-color: #fff; /* White background for contrast */
}

.close-icon-personal {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 1.5rem;
  color: #ff0000; /* Red color for the close icon */
  cursor: pointer;
  z-index: 10;
}

.number-input-field {
  width: 100%;
  border: 2px solid #007BFF; /* Blue border */
  border-radius: 5px; /* Slightly rounded corners for rectangle */
  font-size: 1rem;
  box-sizing: border-box;
  width: 40px;
  background-color: #fff; /* White background for contrast */
}

@media (min-width: 768px) {
	.number-input-field {
	  width: 60px;
	}
}

.clear-icon {
  position: absolute;
  right: 40px; /* Space to the left of the submit button */
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #888; /* Grey color for the clear icon */
  font-size: 2rem;
  background: none;
  border: none;
  outline: none;
  padding: 5px;
  transition: color 0.3s ease;
}

.clear-icon:hover {
  color: #555; /* Darker grey on hover */
}
/* Style for Add button */
.operation-button-add {
  background-color: #28a745; /* Green background for add */
  color: white; /* White text color for visibility */
  border: none; /* Remove default border */
  border-radius: 5px; /* Slightly rounded corners */
  padding: 10px 15px; /* Padding for consistent size */
  cursor: pointer; /* Pointer cursor for interaction */
  font-size: 1rem; /* Standard font size */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

.operation-button-add:hover {
  background-color: #218838; /* Darker green on hover */
}

/* Style for Remove button */
.operation-button-remove {
  background-color: #dc3545; /* Red background for remove */
  color: white; /* White text color for visibility */
  border: none; /* Remove default border */
  border-radius: 5px; /* Slightly rounded corners */
  padding: 10px 15px; /* Padding for consistent size */
  cursor: pointer; /* Pointer cursor for interaction */
  font-size: 1rem; /* Standard font size */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

.operation-button-remove:hover {
  background-color: #c82333; /* Darker red on hover */
}
.submit-icon-button-add, .submit-icon-button-remove {
  position: absolute;
  right: 5px; /* Align at the right edge of the input */
  top: 42%;
  transform: translateY(-50%);
  background-color: #007BFF; /* Blue background */
  color: white; /* White text color */
  border: none;
  width: 30px; /* Square size */
  height: 30px; /* Square size */
  font-size: 1.2rem;
  border-radius: 5px; /* Matching the input field */
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  transition: background-color 0.3s ease;
  display: flex; /* Enables flexbox layout */
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
}

.submit-icon-button-add {
  background-color: #28a745; /* Green background for add button */
}

.submit-icon-button-remove {
  background-color: #dc3545; /* Red background for remove button */
}
.submit-icon-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: translateY(-50%);
}

.submit-icon-button:disabled {
  background-color: #ccc; /* Grey background for disabled state */
  cursor: not-allowed;
}

@media (max-width: 768px) {
    .input-filter-form {
	    max-width: 100%;
    }
}

.info-container {
  display: flex;
  margin: 0px;
}

.info-container-mobile {
  justify-content: space-between; /* Distributes items evenly */
  width: 100%; /* Ensures it uses full width on mobile */
}

.request-lend {
  margin-left: auto; /* Push to the right on mobile view */
}
