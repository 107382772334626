.event-requests-view {
}

.event-requests-view ul {
  list-style-type: none;
  padding: 0;
}

.request-item-container {
  display: flex;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}
@media	(max-width: 768px) {
   .request-item-container {
     flex-direction: column;
}
}

.request-item {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.member-name {
  font-weight: bold;
  margin-right: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  line-height: 3;
}

.collection-item-wrapper {
  flex: 1; /* Occupy remaining space */
  display: flex;
  flex-direction: column; /* Stack CollectionItem and RequestedQuantities vertically */
}

.requested-quantities-wrapper {
  margin-top: 10px; /* Space between CollectionItem and RequestedQuantities */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.available-cards {
  display: flex;
  margin-top: 10px;
}

.normal-foil-container-lend {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 10px; /* Add some space between collection-item and normal-foil-container */
}

.lend-labels {
  display: flex;
  gap: 20px;
  margin-left: 20px;	
  flex-direction: column;
  text-align: right;
}

.lend-inputs {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.lend-inputs input {
  text-align: center;
}

.lend-copies {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.requested-quantities {
  display: flex;
  flex-direction: column;
}

.requested-quantities label {
  margin-right: 10px;
}

.requested-quantities input {
  width: 60px;
  margin-right: 10px;
}

.badge-with-input{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.input-warning {
  border: 1px solid red;
  background-color: #ffe6e6;
}

.warning {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

.event-requests-view .clickable-text {
  cursor: pointer;
  color: blue; /* Color to suggest it's clickable */
}

.user-filter {
  position: relative;
  display: flex;
  margin-bottom: 20px;
}

.user-filter input {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-right: 10px;
  width: 200px;
}

.user-filter button {
  padding: 8px 12px;
  border-radius: 4px;
  border: none;
  background-color: #3498db;
  color: white;
  cursor: pointer;
}

.user-filter button:hover {
  background-color: #2980b9;
}

.suggestions {
  position: absolute;
  top: 100%; /* This ensures the suggestions appear just below the input */
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000;
  margin-top: 4px; /* Optional: adds a small gap between input and suggestions */
}
.suggestions li {
  padding: 8px;
  cursor: pointer;
}

.suggestions li:hover {
  background-color: #f0f0f0;
}

.user-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.user-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 400px;
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.user-modal-content input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box; /* Ensure the input width includes padding and border */
}

.user-modal-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
}

.user-modal-content li {
  padding: 8px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.user-modal-content li:hover {
  background-color: #f0f0f0;
}

.user-modal-content button {
  margin-top: 20px;
  padding: 8px 12px;
  border-radius: 4px;
  border: none;
  background-color: #e74c3c;
  color: white;
  cursor: pointer;
}

.user-modal-content button:hover {
  background-color: #c0392b;
}

.event-operations {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media	(max-width: 768px) {
   .event-operations {
		   flex-direction: column;
   }
}

.coll-item-lending {
  display: flex;
  gap: 10px;
  margin: 0px;
}


.quantities-button {
  display: flex;
  align-items: center;
  margin: 0 5px;
  padding: 5px 10px;
  font-size: 1.25em;
  border-radius: 8px; /* Rounded corners */
  background-color: white; /* White background */
  color: #3498db; /* Blue text color */
  border: 2px solid #3498db; /* Blue border */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
  transition: box-shadow 0.3s; /* Transition effect for box-shadow */
  cursor: pointer;
}

.confirmed-quantities{
  display: flex;
  align-items: center;
  margin: 0 5px;
  padding: 5px 10px;
  font-size: 1.25em;
  font-weight: bold;
  border-radius: 8px; /* Rounded corners */
  background-color: white;
  color: green; /* Blue text color */
  transition: box-shadow 0.3s; /* Transition effect for box-shadow */

}
.confirmed-button {
  display: flex;
  align-items: center;
  margin: 0 5px;
  padding: 5px 10px;
  font-size: 1.25em;
  border-radius: 8px; /* Rounded corners */
  background-color: white; /* White background */
  color: #3498db; /* Blue text color */
  border: 2px solid #3498db; /* Blue border */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
  transition: box-shadow 0.3s; /* Transition effect for box-shadow */
}

.confirm-all-button {
  background-color: #28a745; /* Green color indicating success or confirmation */
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 12px 20px; /* Consistent padding for touch-friendly interaction */
  border: none;
  border-radius: 8px; /* Rounded corners for a modern look */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for a 3D effect */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
}

.confirm-all-button:hover {
  background-color: #218838; /* Slightly darker shade of green on hover */
  transform: translateY(-2px); /* Slight upward movement to indicate interactivity */
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.25); /* Enhanced shadow on hover */
}

.confirm-all-button:active {
  background-color: #1e7e34; /* Even darker shade when the button is pressed */
  transform: translateY(0px); /* Reset the movement */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Reset the shadow */
}

.confirm-all-button:focus {
  outline: 2px solid #218838; /* Green outline for accessibility when focused */
  outline-offset: 2px; /* Slight gap between the button and the outline */
}

/* Specific styles for action buttons */
.lend-button,
.clear-button,
.confirm-all-button {
  background-color: #3498db; /* Default blue color for action buttons */
  color: white;
  font-size: 0.9rem; /* Slightly smaller font size */
  font-weight: bold;
  padding: 8px 16px; /* Reduced padding to make buttons smaller */
  border: none;
  border-radius: 5px; /* Consistent rounded corners */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  width: 100px; /* Set a consistent width */
}

.lend-button:hover,
.clear-button:hover,
.confirm-all-button:hover {
  background-color: #2980b9; /* Darker blue on hover */
}

.lend-button:active,
.clear-button:active,
.confirm-all-button:active {
  background-color: #1c6a9e;
  transform: translateY(2px);
}

.confirm-all-button {
  background-color: #28a745; /* Green for confirm actions */
}

.confirm-all-button:hover {
  background-color: #218838; /* Darker green on hover */
}

.confirm-all-button:active {
  background-color: #1e7e34; /* Even darker green when pressed */
}

.clear-button {
  background-color: #e74c3c; /* Red for clear actions */
}

.clear-button:hover {
  background-color: #c0392b; /* Darker red on hover */
}

.clear-button:active {
  background-color: #a93226; /* Even darker red when pressed */
}


