.clusters-view {
  padding: 20px;
}

.clusters-view h2 {
  text-align: center;
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 20px;
}

.clusters-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 20px;
  justify-items: center;
  padding: 20px;
}

.cluster-frame {
  position: relative;
  width: 180px;
  height: 120px;
  background-color: #fff;
  border: 2px solid #3498db;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cluster-frame:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}


.delete-icon, .edit-icon {
  position: absolute;
  top: -15px;
  font-size: 24px; /* Increased size */
  cursor: pointer;
  width: 30px; /* Increased size */
  height: 30px; /* Increased size */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: white;
  border: 2px solid;
  transition: background-color 0.2s ease, color 0.2s ease;
  z-index: 10;
}

.edit-icon {
  top: -15px;
  right: 50px; /* Increased gap */
  color: #3498db;
  border-color: #3498db;
}

.delete-icon {
  top: -15px;
  right: 10px;
  color: #e74c3c;
  border-color: #e74c3c;
}

.delete-icon:hover, .edit-icon:hover {
  background-color: #e74c3c;
  color: white;
}

.edit-icon:hover {
  background-color: #3498db;
  color: white;
}
.close-icon {
  right: 8px;
}

.create-cluster {
  background-color: #f9f9f9;
  border: 2px dotted #3498db;
  color: #3498db;
  font-size: 2rem;
  font-weight: bold;
}

.create-cluster .create-cluster-placeholder {
  font-size: 2.5rem;
  color: #3498db;
}

.create-cluster-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create-cluster-form input {
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 8px;
  border: 2px solid #ccc;
  width: 120px;
}

.create-cluster-form button {
  padding: 8px 15px;
  border: none;
  border-radius: 8px;
  background-color: #3498db;
  color: white;
  cursor: pointer;
  font-weight: bold;
}

.create-cluster-form button:hover {
  background-color: #2980b9;
}

.cluster-name {
  font-size: 1.4rem;
  font-weight: bold;
  color: #2c3e50;
}

.delete-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.delete-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 300px;
}

.delete-modal-content h3 {
  margin-bottom: 15px;
  color: #c0392b;
}

.delete-modal-content p {
  margin-bottom: 20px;
  color: #333;
}
