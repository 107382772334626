/* Mobile footer styles */
.mobile-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #2c3e50; /* Deep blue background */
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Top shadow */
  z-index: 1000; /* Ensure it's above other content */
  margin: 0;
}

.mobile-footer-link {
  color: #ecf0f1; /* Light text color */
  font-size: 1rem;
  text-decoration: none;
  padding: 10px;
  flex-grow: 1; /* Evenly distribute space among links */
  text-align: center; /* Center text within the link area */
}

.mobile-footer-link:hover {
  background-color: #34495e; /* Slightly lighter on hover */
}

/* Main content area for mobile to avoid footer overlap */
.mobile-content {
  padding-bottom: 80px; /* Space for the bottom footer */
}

