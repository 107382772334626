.lending-item-container {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
  display: column;
  align-items: center;
  text-align: left;
}
.lending-header-container {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
}

.lending-item {
  flex: 1;
  display: flex;
  align-items: center;
}

.quantity-label {
  font-weight: bold;
  margin-right: 10px;
}

.description-label {
  margin-left: auto;
  padding-left: 10px;
}

.cancel-button {
  padding: 5px 10px;
  background-color: red; /* Less aggressive red */
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
.modify-button {
  padding: 5px 10px;
  background-color: #61dafb;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.cancel-button:hover {
  background-color: #e08080;
}

.cancel-button:focus {
  outline: none;
}

